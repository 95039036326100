import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Tooltip } from "@mui/material";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import IconButton from "../../../../UI/IconButton";
import { IOpenFileProps, TMimeTypeMap } from "./models";

const translationPath = "components.fileItem.";

export const OpenFile = memo(
  ({ fileName, loading, onOpenFileInNewTab }: IOpenFileProps) => {
    const { t } = useTranslation();

    const onClickHandler = useCallback(async () => {
      const mimeTypeMap: TMimeTypeMap = {
        jpg: "image/jpeg",
        jpeg: "image/jpeg",
        png: "image/png",
        gif: "image/gif",
        txt: "text/plain",
        pdf: "application/pdf",
        zip: "application/zip",
      };

      const file = await onOpenFileInNewTab();

      if (!file) return;

      const byteCharacters = atob(file.data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      console.log(file.format);

      const blob = new Blob([byteArray], {
        type: mimeTypeMap[file.format.toLowerCase()] || "application/pdf",
      });

      const blobUrl = URL.createObjectURL(blob);

      const newTab = window.open();
      if (!newTab) return;
      newTab.document.write(`
        <html>
          <head>
            <title>${fileName}</title>
          </head>
          <body style="margin:0">
            <iframe src="${blobUrl}" style="border:none; width:100%; height:100vh;"></iframe>
          </body>
        </html>
      `);
      newTab.document.close();
    }, [onOpenFileInNewTab, fileName]);

    return (
      <Tooltip title={t(`${translationPath}open_new_tab`)} placement="left">
        <Box>
          <IconButton size="small" disabled={loading} onClick={onClickHandler}>
            <OpenInNewIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
    );
  }
);
