import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Tooltip, Typography } from "@mui/material";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import useDownload from "../../../../../hooks/useDownload";
import { tokenTemporaryService } from "../../../../../services/tokenTemorary.service";
import IconButton from "../../../../UI/IconButton";
import { IReportItemProps } from "./models";

const EXAMS_ROUTE = process.env.REACT_APP_APP + "/api/v1/LaboratorialExams/";

const EXAMS_TASY_ROUTE =
  process.env.REACT_APP_APP + "/api/v1/LaboratorialExams/Tasy/";

const downloadTranslationPath = "components.examsDownload.";

const translationPath = "components.reports.";

export const ReportFileItem = memo(
  ({
    activeFile,
    index,
    id,
    date,
    examType,
    individualId,
    onSelectFile,
  }: IReportItemProps) => {
    const { t } = useTranslation();

    const isLogginTemporary = tokenTemporaryService.hasTemporaryAccessToken();

    const { downloadFile, loading: dLoading } = useDownload();

    const previewClickHandler = () => {
      onSelectFile(index);
    };

    const downloadHandler = useCallback(() => {
      downloadFile(
        isLogginTemporary ? EXAMS_TASY_ROUTE : EXAMS_ROUTE,
        id,
        `${t(downloadTranslationPath + "report")}-${date.substring(0, 10)}`,
        {
          examType:
            examType === "LABORATORIAL"
              ? "LaboratorialExam"
              : "NonLaboratorialExam",
          individualId,
        }
      );
    }, [downloadFile, t, isLogginTemporary, individualId, date, examType, id]);

    return (
      <Box
        sx={(t) => ({
          ml: 1,
          p: 1,
          pl: 2,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: 2,
          cursor: "pointer",
          transition: "ease-in-out 0.2s",
          backgroundColor:
            index === activeFile ? t.palette.grey[200] : "inherit",
          "&:hover": {
            backgroundColor:
              t.palette.grey[t.palette.mode === "light" ? 200 : 700],
          },
        })}
        onClick={previewClickHandler}
      >
        <Typography fontWeight="bold">
          {t(translationPath + "file")} {index}
        </Typography>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Tooltip title={t(translationPath + "view")} placement="left">
            <Box>
              <IconButton size="small" onClick={previewClickHandler}>
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </Box>
          </Tooltip>
          <Tooltip title={t(translationPath + "download")} placement="right">
            <Box>
              <IconButton
                size="small"
                loading={dLoading}
                disabled={dLoading}
                onClick={downloadHandler}
              >
                <DownloadIcon fontSize="small" />
              </IconButton>
            </Box>
          </Tooltip>
        </Box>
      </Box>
    );
  }
);
