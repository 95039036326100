import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

import CancelSchedule from "./CancelSchedule";
import { IScheduleAppointment } from "../../../model";

interface ICancelScheduleButton {
  tasySchedulingTypeCode: string;
  appointment: IScheduleAppointment;
  onCancelled: () => void;
}

const CancelScheduleButton: React.FC<ICancelScheduleButton> = ({
  appointment,
  tasySchedulingTypeCode,
  onCancelled,
}) => {
  const { t } = useTranslation();
  const [openCancelModal, setOpenCancelModal] = useState(false);

  return (
    <>
      {tasySchedulingTypeCode && appointment && (
        <CancelSchedule
          data={appointment}
          type={tasySchedulingTypeCode}
          onClose={() => setOpenCancelModal(false)}
          open={openCancelModal}
          onCancelled={onCancelled}
        />
      )}
      <Button
        onClick={() => setOpenCancelModal(true)}
        color="error"
        variant="outlined"
      >
        {t("page.schedules.scheduleDetails.cancel.button")}
      </Button>
    </>
  );
};

export default memo(CancelScheduleButton);
