import { memo, useCallback, useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useFormContext } from "react-hook-form";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import useFetch from "../../../../hooks/useFetch";
import { ScheduleInsuranceService } from "../../Insurance/insurance.service";
import { IInsuranceFavorite } from "../../Insurance/model";
import RadioGroupInput from "../../../../components/UI/Inputs/RadioGroupInput";
import { TAppointment } from "../schema";
import Loading from "../../../../components/Loading";
import ModalComponent from "../../../../components/UI/Modal";
import InsuranceForm from "../../Insurance/AddInsurance/InsuranceForm";

const Insurance = () => {
  const [insurances, setInsurances] = useState<IInsuranceFavorite[]>([]);

  const [open, setOpen] = useState(false);

  const { getValues } = useFormContext<TAppointment>();

  const { t } = useTranslation();

  const { sendRequest, loading } = useFetch(ScheduleInsuranceService.list);

  const fetch = useCallback(async () => {
    const { patient } = getValues();

    if(!patient.individualId) return

    const { data, success } = await sendRequest({
      IndividualId: patient.individualId,
    });

    if (data && success) {
      setInsurances(data);
    }
  }, [sendRequest, getValues]);

  const onClickHandler = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuccess = () => {
    handleClose();
    fetch();
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <ModalComponent open={open} onClose={handleClose}>
        <Typography variant="h5">
          {t("page.schedules.newSchedule.insurance")}
        </Typography>
        <InsuranceForm onSuccess={handleSuccess} />
      </ModalComponent>
      <Stack gap={1}>
        <RadioGroupInput
          name="insurance"
          groupLabel={t("page.schedules.newSchedule.insurance")}
          valueField="id"
          disableBorder
          radios={insurances.map((i) => ({
            label: i.insuranceName,
            value: { name: i.insuranceName, id: i.code },
          }))}
        />
        <Stack
          alignItems={"center"}
          direction={"row"}
          onClick={onClickHandler}
          gap={1}
          sx={{
            cursor: "pointer",
            "&:hover": {
              opacity: 0.7,
            },
          }}
        >
          <AddCircleOutlineIcon color="inherit" fontSize="small" />
          <Typography>
            {t("page.schedules.newSchedule.addInsurance")}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default memo(Insurance);
