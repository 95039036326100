import axios, { InternalAxiosRequestConfig } from "axios";
import { userService } from "../services/user.service";
import { authService } from "../services/auth.service";
import { tokenService } from "../services/token.service";

import { router } from "../App";

import store from "../store/store";
import { notificationSliceActions } from "../store/slices/notification";
import { userSliceActions } from "../store/slices/user";
import { privilegeSliceActions } from "../store/slices/privilege";
import { permissionSliceActions } from "../store/slices/permission";
import { getDomain } from "../services/domain.service";
import i18n from "../i18n";

const GlobalAxios = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    client_id: process.env.CLIENT_ID,
  },
});

GlobalAxios.interceptors.request.use((config) => {
  config.headers.Domain = getDomain();

  // Add extension code do calendar routes
  if (config.url?.startsWith(process.env.REACT_APP_CALENDAR!)) {
    config.headers.ExtensionCode = 25;
  }

  return config;
});

GlobalAxios.interceptors.request.use((config) => {
  config.headers["Accept-Language"] = i18n.language;

  return config;
});

GlobalAxios.interceptors.request.use(function (config) {
  let token = tokenService.getAccessToken();

  config.headers.Authorization = "Bearer " + token;

  return config;
});

GlobalAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const config = error?.config;
    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;
      if (!tokenService.hasRefreshTokens()) {
        logoutUser();
        return;
      }

      const tokens = await refreshToken();

      if (tokens) {
        tokenService.saveTokens(tokens.access_token, tokens.refresh_token);
        config.headers.Authorization = "Bearer " + tokens.access_token;
      }

      return GlobalAxios(config);
    }

    return Promise.reject(error);
  }
);

export const InterceptorRefresh = async (
  config: InternalAxiosRequestConfig<any>
) => {
  if (!tokenService.hasRefreshTokens()) {
    return config;
  }

  let tokens = tokenService.getTokens();

  if (tokens && tokens.access_token && tokens.refresh_token) {
    tokenService.saveTokens(tokens.access_token, tokens.refresh_token);
    config.headers.Authorization = "Bearer " + tokens.access_token;
  }

  return config;
};

const logoutUser = () => {
  tokenService.removeAllTokens();
  userService.removeUserName();
  store.dispatch(userSliceActions.removeUser());
  store.dispatch(privilegeSliceActions.removePrivilege());
  store.dispatch(permissionSliceActions.removePermission());
  store.dispatch(
    notificationSliceActions.showNotification({
      message: "login_expired",
      type: "error",
      translate: true,
    })
  );
  router.navigate("/login", {
    replace: true,
  });
};

const refreshToken = async () => {
  try {
    const response = await authService.refresh_token();
    return response.data;
  } catch (error) {
    logoutUser();
  }
};

export default GlobalAxios;
