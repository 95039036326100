import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IHealthProgram } from "../../models/health-programs";
import { tokenService } from "../../services/token.service";
import useFetch from "../../hooks/useFetch";
import {
  getHealthProgramsAuthenticated,
  getHealthProgramsNonAuthenticated,
  leaveProgram,
  requestAccess,
} from "../../services/healthPrograms.service";
import { useTranslation } from "react-i18next";

interface IHealthProgramContext {
  healthPrograms: IHealthProgram[];
  loading: boolean;
  requestLoading: boolean;
  leaveLoading: boolean;
  requestAccessHandler: (id: string) => void;
  leaveProgramHandler: (id: string) => void;
}

interface IHealthProgramsConetxtProps {
  children: JSX.Element;
}

const HealthProgramsContext = createContext<IHealthProgramContext>({
  healthPrograms: [],
  loading: false,
  requestLoading: false,
  leaveLoading: false,
  requestAccessHandler: () => {},
  leaveProgramHandler: () => {},
});

export const HealthProgramsProvider: React.FC<IHealthProgramsConetxtProps> = ({
  children,
}) => {
  const isLogged = !!tokenService.getAccessToken();

  const [healthPrograms, setHealthPrograms] = useState<IHealthProgram[]>([]);

  const {
    i18n: { language },
  } = useTranslation();

  const { sendRequest, loading } = useFetch(
    isLogged
      ? getHealthProgramsAuthenticated
      : getHealthProgramsNonAuthenticated
  );

  const { sendRequest: requestProgramAccess, loading: requestLoading } =
    useFetch(requestAccess);

  const { sendRequest: requestLeaveProgram, loading: leaveLoading } =
    useFetch(leaveProgram);

  const fetchHealthPrograms = useCallback(async () => {
    const { data, success } = await sendRequest(null);
    if (data && success) {
      setHealthPrograms(data);
    } else {
      setHealthPrograms([]);
    }
  }, [sendRequest]);

  useEffect(() => {
    if (language) {
      fetchHealthPrograms();
    }
  }, [fetchHealthPrograms, language]);

  const requestAccessHandler = useCallback(
    async (id: string) => {
      const { success } = await requestProgramAccess(id);
      if (success) {
        setHealthPrograms((programs) => {
          return programs.map((program) => {
            if (program.id === id) {
              return {
                ...program,
                hasRequestedToEnter: true,
                deniedMessage: null,
                disapprovedRequest: false,
                denialReason: null,
              };
            }
            return program;
          });
        });
      }
    },
    [requestProgramAccess]
  );

  const leaveProgramHandler = useCallback(
    async (id: string) => {
      const { success } = await requestLeaveProgram(id);
      if (success) {
        setHealthPrograms((programs) => {
          return programs.map((program) => {
            if (program.id === id) {
              return {
                ...program,
                isInProgram: false,
                hasRequestedToEnter: false,
                disapprovedRequest: false,
                denialReason: null,
              };
            }
            return program;
          });
        });
      }
    },
    [requestLeaveProgram]
  );

  const value = useMemo(
    () => ({
      healthPrograms,
      loading,
      requestLoading,
      leaveLoading,
      requestAccessHandler,
      leaveProgramHandler,
    }),
    [
      healthPrograms,
      loading,
      requestLoading,
      leaveLoading,
      requestAccessHandler,
      leaveProgramHandler,
    ]
  );

  return (
    <HealthProgramsContext.Provider value={value}>
      {children}
    </HealthProgramsContext.Provider>
  );
};

export const useHealthProgramsContext = () => {
  const context = useContext(HealthProgramsContext);
  return context;
};
