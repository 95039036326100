import { ISelectType } from "../../components/UI/Inputs/AutoComplete";

export interface ISchedule {
  identifier: number;
  pacientName: string;
  calendarIdentifier: number;
  descriptionSite: string;
  calendarDate: string;
  tasySchedulingType: ISelectType;
}

export interface ISpeciality {
  id: string;
  integrationId: string;
  name: string;
  heathCodeCboIntegrationId: string;
  enable: boolean;
  isMedicalGuide: boolean;
  displayOrder: number;
}

export interface IProfessional {
  establishmentCode: number;
  identifier: number;
  calendarName: string;
  calendarShortName: string;
  descriptionSite: string;
  tasyScheduleTypeCode: number;
  tasyScheduleTypeName: string;
  individualIdentifier: string;
  individualName: string;
  enableCode: string;
  enableName: string;
  medicalSpecialtyCode: number;
  medicalSpecialtyName: string;
  observation: string;
  encounterTypeCode: number;
  encounterTypeName: string;
  exclusiveSectorCode: number;
  exclusiveSectorName: string;
  groupingIdentifier: number;
  groupingName: string;
}

export interface IProfessionalSearchParams {
  MedicalSpecialtyCode: String;
  searchBy?: string;
}

export interface IGroupExam {
  groupingIdentifier: number;
  groupingName: string;
  displayOrder: number;
  typeCalendar: string;
  enableCode: string;
}

interface IBasicAvaiableDates {
  MonthYear: string;
  ScheduleShiftCodeList?: string[];
  MinDate: string;
  CalendarIdentifier?: string;
}

export interface IGetConsultationAvaiableDatesParams
  extends IBasicAvaiableDates {
  MedicalSpecialtyCode?: string;
}

export interface IExamAvaiableDatesParams extends IBasicAvaiableDates {
  GroupingIdentifier: number;
  MinDate: string;
}
export interface IGetExamAvaiableScheduleParams
  extends Omit<IExamAvaiableDatesParams, "MinDate"> {
  Date: string;
}

export interface IGetConsultationAvaiableScheduleParams
  extends Omit<IGetConsultationAvaiableDatesParams, "MinDate"> {
  Date: string;
}

export interface IAvaiableDates {
  dates: string[];
}

export interface IAvaiableSchedule {
  identifier: number;
  calendarName: string;
  calendarShortName: string;
  descriptionSite: string;
  schedules: ISchedule[];
}

export interface IIScheduleConsultationParams {
  identifier: number;
  pacientIndividualIdentifier: string;
  insuranceCode: string;
}

export interface IScheduleProps {
  data: ISchedule;
}

export interface ITasySchedCheckInRule {
  id: string;
  metersApproximation: number;
  time: number;
}

export interface ITasySchedConfirmationRule {
  id: string;
  time: number;
  establishmentId: string;
}

export enum EcalendarStatusCode {
  CANCELED = "C",
  CONFIMED = "AC",
  EXECUTED = "E",
  NORMAL = "N",
}

export enum ETasyScheduleTypeCode {
  EXAM = "2",
  CONSULTATION = "3",
}

export interface IScheduleAppointment {
  groupingIdentifier: number;
  groupingName: string;
  roomIdentifier: number;
  roomName: number;
  tasyScheduleTypeCode: ETasyScheduleTypeCode;
  tasyScheduleTypeName: string;
  identifier: number;
  insuranceCode: number;
  insuranceName: string;
  insuranceCardNumber: string;
  insuranceCardValidity: string;
  calendarClassificationCode: string;
  calendarClassificationName: string;
  pacientIndividualIdentifier: string;
  pacientName: string;
  physicianNickName: string;
  professionalCouncilNumber: string;
  physicianCodeExecute: string;
  physicianNameExecute: string;
  council: string;
  calendarIdentifier: number;
  calendarName: string;
  calendarShortName: string;
  descriptionSite: string;
  calendarDate: string;
  calendarStartDate: string;
  calendarEndDate: string;
  minutesDuration: number;
  observation: string;
  scheduleShiftCode: string;
  scheduleShiftName: string;
  calendarStatusCode: EcalendarStatusCode;
  calendarStatusName: string;
  establishmentCode: number;
  enableCode: string;
  enableName: string;
}

export interface IConsulationScheduleAppointment {
  scheduleAppointment: IScheduleAppointment;
  tasySchedCheckInRule: ITasySchedCheckInRule;
  tasySchedConfirmationRule: ITasySchedConfirmationRule;
}

export interface IExamProcedure {
  procedureInternalCode: number;
  procedureOriginCode: number;
  procedureName: string;
  procedureTussCode: number;
  procedureTussName: string;
}

interface IExamAppointment extends IScheduleAppointment, IExamProcedure {}
export interface IExamScheduleAppointment {
  scheduleAppointment: IExamAppointment;
  tasySchedCheckInRule: ITasySchedCheckInRule;
  tasySchedConfirmationRule: ITasySchedConfirmationRule;
}

export interface ICancelationReason {
  identifier: number;
  establishmentCode: number;
  cancellationReasonCode: string;
  cancellationReasonName: string;
  enableCode: string;
  enableName: string;
}

export interface ICalendarCancellationParams {
  identifier: string;
  identifierCalendar: string;
  calendarDate: string;
  cancellationReasonCode: string;
  cancellationReasonDescription: string;
}

export interface IIICalendarExamScheduleConfirmationParams {
  identifier: number;
  identifierCalendar: number;
  calendarDate: string;
}

export interface IICalendarConsultationScheduleConfirmationParams {
  identifier: number;
  identifierCalendar: number;
}
