import { Stack, Typography } from "@mui/material";
import { ISchedule } from "../model";
import { Calendar } from "./Calendar";
import { Type } from "./Type";
import { useNavigate } from "react-router-dom";

const ScheduleItem: React.FC<ISchedule> = (data) => {
  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate(
      `/schedule/mySchedules/${data.tasySchedulingType.code}/${data.identifier}`
    );
  };

  return (
    <Stack
      sx={(t) => ({
        flexDirection: "row",
        gap: t.spacing(2),
        padding: t.spacing(2),
        backgroundColor: t.palette.divider,
        borderRadius: t.shape.borderRadius / 2,
      })}
      onClick={onClickHandler}
    >
      <Calendar date={data.calendarDate} />
      <Stack
        sx={(t) => ({
          justifyContent: "space-between",
          flexGrow: 1,
          padding: t.spacing(1),
          flex: 1,
        })}
      >
        <Stack
          sx={(t) => ({
            flexDirection: "row",
            justifyContent: "space-between",
            gap: t.spacing(1),
            alignItems: "center",
          })}
        >
          <Typography style={{ flex: 1, fontWeight: "500" }}>
            {data.descriptionSite}
          </Typography>
          {data.tasySchedulingType.code && (
            <Type type={data.tasySchedulingType.code} />
          )}
        </Stack>
        <Typography style={{ alignSelf: "flex-end", fontSize: 15 }}>
          {data.pacientName}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ScheduleItem;
