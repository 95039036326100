import { Stack, SxProps, Theme } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { memo } from "react";

import { TAddHealth, addInsuranceSchema } from "./schema";
import DateInput from "../../../../components/UI/Inputs/DateInput";
import FormInput from "../../../../components/UI/Inputs/FormInput";
import SelectInsurance from "./SelectInsurance";
import Button from "../../../../components/UI/Button";
import SelectFamilyMember from "./SelectFamilyMember";
import { useInsurance } from "./useInsurance";
import Loading from "../../../../components/UI/Loading";

const KEYS = addInsuranceSchema.keyof().Enum;

interface IInsuranceFormProps {
  onSuccess: () => void;
  sx?: SxProps<Theme>;
}

const InsuranceForm: React.FC<IInsuranceFormProps> = ({ onSuccess,sx }) => {
  const { t } = useTranslation();

  const methods = useForm<TAddHealth>({
    resolver: zodResolver(addInsuranceSchema),
  });

  const { isEditMode, loading, getLoading, onSubmit } = useInsurance(
    methods,
    onSuccess
  );

  return (
    <FormProvider {...methods}>
      <Stack
        gap={2}
        sx={sx}
      >
        {getLoading && <Loading />}
        <SelectInsurance />
        <SelectFamilyMember disable={isEditMode} />
        <DateInput
          hasDefaultDate={false}
          label={t("page.schedules.insurance.addInsurance.validity.label")}
          name={KEYS.validate}
        />
        <FormInput
          size="small"
          fullWidth
          label={t("page.schedules.insurance.addInsurance.number.label")}
          name={KEYS.code}
        />
        <Button
          variant="contained"
          sx={{ alignSelf: "end" }}
          onClick={methods.handleSubmit(onSubmit)}
          loading={loading}
        >
          {isEditMode
            ? t("page.schedules.insurance.addInsurance.buttonEdit")
            : t("page.schedules.insurance.addInsurance.buttonSave")}
        </Button>
      </Stack>
    </FormProvider>
  );
};

export default memo(InsuranceForm);
