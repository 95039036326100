import { Grid, Stack } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import { TAppointment } from "../../schema";
import ConfirmDate from "../../Stepper/ConfirmDate";
import useDate from "../../../../../hooks/useDate";
import Row from "../../../../../components/UI/Row";

const Confirm = () => {
  const { getValues } = useFormContext<TAppointment>();

  const { t } = useTranslation();

  const { dayjs } = useDate();

  const values = getValues();

  return (
    <Grid container gap={3} flexDirection={"row"}>
      <Stack spacing={1}>
        <Row
          label={t("page.schedules.newSchedule.confirm.descriptionSite")}
          value={values.schedule.descriptionSite}
        />
        <Row
          label={t("page.schedules.newSchedule.confirm.patient")}
          value={values.patient.name}
        />

        <Row
          label={t("page.schedules.newSchedule.confirm.insurance")}
          value={values.insurance.name}
        />
      </Stack>
      <Grid item xs={true}>
        <ConfirmDate
          duration={values.schedule.minutesDuration}
          startHour={dayjs(values.schedule.calendarStartDate)}
        />
      </Grid>
    </Grid>
  );
};

export default memo(Confirm);
