import { Stack } from "@mui/material";
import { memo } from "react";

import { ISchedule } from "../model";
import ScheduleItem from "../Schedule/ScheduleItem";

interface IScheduleListProps {
  data: ISchedule[];
}

const ScheduleList: React.FC<IScheduleListProps> = ({ data }) => {
  return (
    <Stack gap={1} mt={1}>
      {data.map((s) => (
        <ScheduleItem {...s} />
      ))}
    </Stack>
  );
};

export default memo(ScheduleList);
