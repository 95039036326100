import GlobalAxios from "../axios/axios-config";
import TemporaryAxios from "../axios/temprary-axios-config";
import { IGetLaboratorialExamsFileParams } from "../models/laboratorial-exams";
import { authApi } from "./auth.service";

const BASE_URL =
  process.env.REACT_APP_APP + "/api/v1/LaboratorialExams/file/base64";

export const getLaboratorialExamsFile = ({
  isTemporary,
  Identifier,
  ImageIdentifier,
}: IGetLaboratorialExamsFileParams) => {
  const params = { Identifier, ImageIdentifier };

  if (isTemporary) {
    return authApi.get<string>(BASE_URL + "/Tasy", { params: params });
  }

  return GlobalAxios.get<string>(BASE_URL, { params: params });
};

export const getLaboratorialExamsFileAsTemporary = ({
  Identifier,
  ImageIdentifier,
}: Omit<IGetLaboratorialExamsFileParams, "isTemporary">) => {
  const params = { Identifier, ImageIdentifier };

  return TemporaryAxios.get<string>(`${BASE_URL}/Tasy/`, { params });
};
