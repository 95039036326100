import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../../hooks/useFetch";
import { ISequencialImageControls } from "../../../models/exams";
import {
  getLaboratorialExamsFile,
  getLaboratorialExamsFileAsTemporary,
} from "../../../services/laboratorialExams.service";
import { tokenTemporaryService } from "../../../services/tokenTemorary.service";
import FileItem from "./FileItem/FileItem";
import { IExamsRedirectFileProps } from "./models";
import { IPreViewFile } from "./PreView/models";
import PreView from "./PreView/PreView";

const translationPath = "components.examsRedirectModal.";

const ExamsRedirectModal = ({
  sequencialImageControls,
  sequencialControl,
}: IExamsRedirectFileProps) => {
  const { t } = useTranslation();

  const [isDownloading, setIsDownloading] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const { sendRequest, loading: isLoading } = useFetch(
    getLaboratorialExamsFile
  );
  const { sendRequest: getTemporaryFile, loading: isLoadingTemporary } =
    useFetch(getLaboratorialExamsFileAsTemporary);

  const [currentFile, setCurrentFile] = useState<IPreViewFile | null>(null);

  const [sideMenuOpen, setSideMenuOpen] = useState(true);

  const toggleMenu = useCallback(() => {
    setSideMenuOpen((prevState) => !prevState);
  }, []);

  const getCurrentImageIdentifier = useCallback(
    async (sequential: ISequencialImageControls, format: string) => {
      const isLogginTemporary =
        !!tokenTemporaryService.getTemporaryAccessToken();

      let response;

      if (isLogginTemporary) {
        response = await getTemporaryFile({
          Identifier: sequencialControl,
          ImageIdentifier: sequential.sequencialImageControl,
        });
      } else {
        response = await sendRequest({
          isTemporary: false,
          Identifier: sequencialControl,
          ImageIdentifier: sequential.sequencialImageControl,
        });
      }

      const { data, success } = response;

      if (data && success) {
        if (format === "pdf" || format === "jpg") {
          setCurrentFile({
            format,
            content: data,
            identifier: sequencialControl,
            imageIdentifier: sequential.sequencialImageControl,
          });
          toggleMenu();
        }
        setCurrentFile(null);
        return { data, format };
      }
    },
    [sendRequest, getTemporaryFile, toggleMenu, sequencialControl]
  );

  const loading = isLoading || isLoadingTemporary;

  return (
    <Box>
      <Box
        sx={{
          mb: 1,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: 1,
        }}
      >
        {matches && currentFile && (
          <IconButton size="small" onClick={toggleMenu}>
            <MenuIcon fontSize="small" />
          </IconButton>
        )}
        <Typography variant="h6">{t(translationPath + "title")}</Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={(t) => ({
            mr: 1,
            width: matches ? (!sideMenuOpen ? undefined : "100%") : undefined,
            display: matches && !sideMenuOpen ? "none" : "flex",
            flexDirection: "column",
            borderRight: matches ? 0 : 1,
            borderRightColor: t.palette.divider,
          })}
        >
          {sequencialImageControls.length > 0 &&
            sequencialImageControls.map((sequential, index) => (
              <FileItem
                key={sequential.sequencialImageControl + index}
                currentImageIdentifier={
                  currentFile ? currentFile.imageIdentifier : null
                }
                previewLoading={loading}
                sequencialControl={sequencialControl}
                sequential={sequential}
                disabled={isDownloading}
                onDownloading={setIsDownloading}
                getCurrentImageIdentifier={getCurrentImageIdentifier}
              />
            ))}
        </Box>
        {matches ? (
          !sideMenuOpen ? (
            <PreView
              file={currentFile}
              previewLoading={loading}
              currentImageIdentifier={
                currentFile ? currentFile.imageIdentifier : null
              }
            />
          ) : null
        ) : (
          <PreView
            file={currentFile}
            previewLoading={loading}
            currentImageIdentifier={
              currentFile ? currentFile.imageIdentifier : null
            }
          />
        )}
      </Box>
    </Box>
  );
};

export default memo(ExamsRedirectModal);
