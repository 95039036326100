import { Button } from "@4uhub/lib4uhub";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { convertBinaryToFile } from "../../../../../utils/conversions";
import RenderPDF from "../../../../RenderPDF/RenderPDF";
import { IReportsFilePreviewProps } from "./models";

const translationPath = "components.reports.";

export const ReportsFilePreview = memo(
  ({ fileSelected, files, onBackToFiles }: IReportsFilePreviewProps) => {
    const { t } = useTranslation();

    const theme = useTheme();

    const matches = useMediaQuery(theme.breakpoints.down("md"));

    const backToFilesHandler = useCallback(() => {
      onBackToFiles(0);
    }, [onBackToFiles]);

    return (
      <Grid item sm={matches ? 12 : files.length > 1 ? 8 : 12}>
        {matches && fileSelected > 0 && (
          <Button
            startIcon={<ArrowBackIcon />}
            sx={{ mb: 2 }}
            onClick={backToFilesHandler}
          >
            {t(translationPath + "back")}
          </Button>
        )}
        {fileSelected > 0 && (
          <Box
            sx={{
              height: matches ? "calc(100vh - 190px)" : "calc(100vh - 150px)",
              width: "100%",
            }}
          >
            {files[fileSelected - 1] && (
              <RenderPDF
                width="100%"
                file={convertBinaryToFile(
                  files[fileSelected - 1].medicalCertificateFile,
                  "Arquivo"
                )}
                downloadButtonProps={{ onDownload: () => {}, loading: false }}
              />
            )}
          </Box>
        )}
      </Grid>
    );
  }
);
