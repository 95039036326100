import GlobalAxios from "../../axios/axios-config";
import { ISelectType } from "../../components/UI/Inputs/AutoComplete";
import {
  IAvaiableDates,
  IAvaiableSchedule,
  ICalendarCancellationParams,
  ICancelationReason,
  IConsulationScheduleAppointment,
  IExamAvaiableDatesParams,
  IExamScheduleAppointment,
  IGetConsultationAvaiableDatesParams,
  IGetConsultationAvaiableScheduleParams,
  IGetExamAvaiableScheduleParams,
  IGroupExam,
  IICalendarConsultationScheduleConfirmationParams,
  IIICalendarExamScheduleConfirmationParams,
  IIScheduleConsultationParams,
  IProfessional,
  IProfessionalSearchParams,
  ISchedule,
  ISpeciality,
} from "./model";

const TASY_SCHEDULE_ROUTE =
  process.env.REACT_APP_CALENDAR +
  "/api/v1/tasy/CalendarConsultationExamScheduleAppointment";

export class ScheduleAppointmentService {
  public static get = (historic: boolean) => {
    return GlobalAxios.get<ISchedule[]>(TASY_SCHEDULE_ROUTE, {
      params: { historic },
    });
  };

  public static getTypes = () => {
    return GlobalAxios.get<ISelectType[]>(
      process.env.REACT_APP_MAIN + "/api/v1/Generic/list",
      {
        params: {
          Identifier: "TasySchedulingType",
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  public static getPeriodOptions = () => {
    return GlobalAxios.get<ISelectType[]>(
      process.env.REACT_APP_MAIN + "/api/v1/Generic/list",
      {
        params: {
          Identifier: "ScheduleShiftCode",
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  public static getSpecialitys = () => {
    return GlobalAxios.get<ISpeciality[]>(
      process.env.REACT_APP_CALENDAR + "/api/v1/MedicalSpecialty/List"
    );
  };

  public static getProfessionals = (params: IProfessionalSearchParams) => {
    return GlobalAxios.get<IProfessional[]>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarConsultationRegister",
      { params }
    );
  };

  public static getExamGroups = () => {
    return GlobalAxios.get<IGroupExam[]>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarExamGroupingRegister"
    );
  };

  public static getConsultationAvaiableDate = (
    params: IGetConsultationAvaiableDatesParams
  ) => {
    return GlobalAxios.get<IAvaiableDates>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarConsultationAvailable/Dates",
      {
        params,
        paramsSerializer: {
          indexes: null,
        },
      }
    );
  };

  public static getConsultationAvaiableSchedules = (
    params: IGetConsultationAvaiableScheduleParams
  ) => {
    return GlobalAxios.get<IAvaiableSchedule[]>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarConsultationAvailable/Schedules",
      {
        params,
        paramsSerializer: {
          indexes: null,
        },
      }
    );
  };

  public static getExamAvaiableDate = (params: IExamAvaiableDatesParams) => {
    return GlobalAxios.get<IAvaiableDates>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarExamAvailable/Dates",
      {
        params,
        paramsSerializer: {
          indexes: null,
        },
      }
    );
  };

  public static getExamAvaiableSchedules = (
    params: IGetExamAvaiableScheduleParams
  ) => {
    return GlobalAxios.get<IAvaiableSchedule[]>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarExamAvailable/Schedules",
      {
        params,
        paramsSerializer: {
          indexes: null,
        },
      }
    );
  };

  public static calendarConsultationScheduling = (
    params: IIScheduleConsultationParams
  ) => {
    return GlobalAxios.put<{ id: string }>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarConsultationScheduling",
      params,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  public static calendarExamScheduling = (
    params: IIScheduleConsultationParams
  ) => {
    return GlobalAxios.put<{ id: string }>(
      process.env.REACT_APP_CALENDAR + "/api/v1/tasy/CalendarExamScheduling",
      params,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  public static getConsulationScheduleAppointment = (identifier: number) => {
    return GlobalAxios.get<IConsulationScheduleAppointment>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarConsultationScheduleAppointment",
      {
        params: { identifier },
      }
    );
  };

  public static getExamScheduleAppointment = (identifier: number) => {
    return GlobalAxios.get<IExamScheduleAppointment>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarExamScheduleAppointment",
      {
        params: { identifier },
      }
    );
  };

  public static getCalendarExamCancellationReason = () => {
    return GlobalAxios.get<ICancelationReason[]>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarExamCancellationReason"
    );
  };

  public static getCalendarConsultationCancellationReason = () => {
    return GlobalAxios.get<ICancelationReason[]>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarConsultationCancellationReason"
    );
  };

  public static calendarConsultationScheduleCancellation = (
    params: ICalendarCancellationParams
  ) => {
    return GlobalAxios.put<{ id: string }>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarConsultationScheduleCancellation",
      params
    );
  };

  public static calendarExamScheduleCancellation = (
    params: ICalendarCancellationParams
  ) => {
    return GlobalAxios.put<{ id: string }>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarExamScheduleCancellation",
      params
    );
  };

  public static calendarConsultationScheduleConfirmation = (
    params: IICalendarConsultationScheduleConfirmationParams
  ) => {
    return GlobalAxios.put<{ id: string }>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarConsultationScheduleConfirmation",
      params,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  public static calendarExamScheduleConfirmation = (
    params: IIICalendarExamScheduleConfirmationParams
  ) => {
    return GlobalAxios.put<{ id: string }>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarExamScheduleConfirmation",
      params,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };
}
