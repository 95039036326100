import GlobalAxios from "../axios/axios-config";
import TemporaryAxios from "../axios/temprary-axios-config";
import { IReportFile, IReportsProps } from "../components/Exams/Reports/models";

const NON_LABORATORIAL_EXAMS_ROUTE =
  process.env.REACT_APP_APP + "/api/v1/NonLaboratorialExams";

export const getAllNonLaboratorialExams = (params: IReportsProps) => {
  return GlobalAxios.get<IReportFile[]>(NON_LABORATORIAL_EXAMS_ROUTE, {
    params,
  });
};

export const getAllNonLaboratorialExamsTasy = (params: IReportsProps) => {
  return TemporaryAxios.get<IReportFile[]>(
    `${NON_LABORATORIAL_EXAMS_ROUTE}/Tasy`,
    {
      params,
    }
  );
};
