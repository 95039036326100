import { Stack, Typography, useTheme } from "@mui/material";
import React, { memo, useMemo } from "react";
import useDate from "../../../hooks/useDate";

export const Calendar: React.FC<{ date: string }> = memo(({ date }) => {
  const {
    palette: { common, grey, primary },
    spacing,
  } = useTheme();

  const { dayjs } = useDate();

  const mDate = useMemo(() => dayjs(date), [dayjs, date]);

  const { day, hour, month, year } = useMemo(
    () => ({
      day: mDate.format("DD"),
      month: mDate.format("MMMM"),
      hour: mDate.format("LT"),
      year: mDate.format("YYYY"),
    }),
    [mDate]
  );

  return (
    <Stack sx={{ overflow: "hidden", borderRadius: 2 }} width={100}>
      <Stack
        sx={{
          backgroundColor: grey[100],
          padding: spacing(1),
          width: "100%",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Typography sx={{ color: common.black, fontSize: 25 }}>
          {day}
        </Typography>
        <Typography sx={{ color: common.black, fontSize: 15 }}>
          {month}
        </Typography>
        <Typography sx={{ color: grey[500], fontSize: 10 }}>{year}</Typography>
      </Stack>
      <Stack sx={{ backgroundColor: primary.main, alignItems: "center" }}>
        <Typography
          sx={{
            padding: spacing(1),
            alignItems: "center",
            color: common.white,
          }}
        >
          {hour}
        </Typography>
      </Stack>
    </Stack>
  );
});
