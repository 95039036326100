import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";

import { TAppointment, appointmentSchema } from "./schema";
import Patient from "./Steps/Patient";
import { IStep } from "./Stepper/models";
import FormStepper from "./Stepper/FormStepper";
import Insurance from "./Steps/Insurance";
import SearchAvailability from "./Steps/SearchAvailability/SearchAvailability";
import Schedule from "./Steps/Schedule/Schedule";
import Confirm from "./Steps/Confirm/Confirm";
import { useConfirm } from "./Steps/Confirm/useConfirm";
import { useAppSelector } from "../../../store/store";
import PagesContainer from "../../../components/UI/PagesContainer";

const STEPS: IStep<TAppointment>[] = [
  {
    label: "page.schedules.newSchedule.steps.patient.title",
    element: <Patient />,
    fields: ["patient"],
  },
  {
    label: "page.schedules.newSchedule.steps.insurance.title",
    element: <Insurance />,
    fields: ["insurance"],
  },
  {
    label: "page.schedules.newSchedule.steps.search.title",
    element: <SearchAvailability />,
    fields: ["search"],
  },
  {
    label: "page.schedules.newSchedule.steps.schedule.title",
    element: <Schedule />,
    fields: ["schedule"],
  },
  {
    label: "page.schedules.newSchedule.steps.confirm",
    element: <Confirm />,
    fields: [],
  },
];

const NewSchedule = () => {
  const { individual } = useAppSelector((state) => state.user);

  const methods = useForm<TAppointment>({
    resolver: zodResolver(appointmentSchema),
    mode: "onChange",
    defaultValues: {
      search: {
        type: "3",
        date: new Date(),
      },
      patient: {
        id: individual.id,
        individualId: individual.id,
        name: individual.name,
      },
    },
  });

  const { t } = useTranslation();

  const { scheduleConsultation, error } = useConfirm();

  const onFinish = async () => {
    const v = methods.getValues();
    await scheduleConsultation(v);
  };

  return (
    <PagesContainer hideHeader>
      <FormProvider<TAppointment> {...methods}>
        <FormStepper<TAppointment>
          steps={STEPS}
          error={error}
          finishLabel={t("page.schedules.newSchedule.sched")!}
          onFinish={onFinish}
        />
      </FormProvider>
    </PagesContainer>
  );
};

export default NewSchedule;
