import { Loading, useFetch } from "@4uhub/lib4uhub";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import {
  getAllNonLaboratorialExams,
  getAllNonLaboratorialExamsTasy,
} from "../../../../services/nonLaboratorialExams.service";
import { tokenTemporaryService } from "../../../../services/tokenTemorary.service";
import NoData from "../../../UI/NoData";
import { IReportFile } from "../models";
import { IReportsModalProps } from "./models";
import { ReportFileItem } from "./ReportFileItem/ReportFileItem";
import { ReportsFilePreview } from "./ReportsFilePreview/ReportsFilePreview";

export const ReportsModal = memo(
  ({ id, date, examType, individualId, identifier }: IReportsModalProps) => {
    const theme = useTheme();

    const matches = useMediaQuery(theme.breakpoints.down("md"));

    const [files, setFiles] = useState<IReportFile[]>([]);

    const [fileSelected, setFileSelected] = useState(matches ? 0 : 1);

    const isTemporaryAccess = !!tokenTemporaryService.getTemporaryAccessToken();

    const { sendRequest, loading } = useFetch(
      isTemporaryAccess
        ? getAllNonLaboratorialExamsTasy
        : getAllNonLaboratorialExams
    );

    const fetchNonLaboratorialExams = useCallback(async () => {
      const { data, success } = await sendRequest({ identifier });
      if (data && success) {
        setFiles(data);
      }
    }, [identifier, sendRequest]);

    useEffect(() => {
      fetchNonLaboratorialExams();
    }, [fetchNonLaboratorialExams]);

    const selectFile = (index: number) => setFileSelected(index);

    if (loading) {
      return (
        <Box
          sx={{
            height: matches ? "calc(100vh - 190px)" : "calc(100vh - 150px)",
          }}
        >
          <Loading />
        </Box>
      );
    }

    if (files.length === 0 && !loading)
      return (
        <Box
          sx={{
            height: matches ? "calc(100vh - 190px)" : "calc(100vh - 150px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NoData />
        </Box>
      );

    return (
      <Grid container>
        <ReportsFilePreview
          fileSelected={fileSelected}
          files={files}
          onBackToFiles={setFileSelected}
        />
        {files.length > 1 &&
          ((matches && fileSelected === 0) ||
            (!matches && fileSelected > 0)) && (
            <Grid
              item
              sm={matches ? 12 : 4}
              sx={{ height: "calc(100vh - 150px)", overflowY: "auto" }}
            >
              {files.map((file, index) => (
                <ReportFileItem
                  key={file.sequencialPdfControl}
                  file={file}
                  index={index + 1}
                  activeFile={fileSelected}
                  id={id}
                  date={date}
                  examType={examType}
                  individualId={individualId}
                  onSelectFile={selectFile}
                />
              ))}
            </Grid>
          )}
      </Grid>
    );
  }
);
