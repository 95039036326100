import { Box, Grid } from "@mui/material";
import { GridColDef, GridEventListener } from "@mui/x-data-grid";
import { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ISchedule } from "../model";
import NoData from "../../../components/UI/NoData";
import Table from "../../../components/UI/Table/Table";
import Type from "../Schedule/Type";
import { Calendar } from "../Schedule/Calendar";

interface IMySchedulesTableProps {
  data: ISchedule[];
}

const TRASNLATION_PATH = "page.schedules.mySchedules.";

const MySchedulesTable: React.FC<IMySchedulesTableProps> = ({ data }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const columns: GridColDef<ISchedule>[] = [
    {
      field: "calendarDate",
      headerName: t(TRASNLATION_PATH + "date")!,
      flex: 1,
      minWidth: 200,
      renderCell: ({ row: { calendarDate } }) => (
        <Calendar date={calendarDate} />
      ),
    },

    {
      field: "pacientName",
      headerName: t(TRASNLATION_PATH + "pacientName")!,
      minWidth: 200,
      flex: 1,
    },
    {
      field: "descriptionSite",
      headerName: t(TRASNLATION_PATH + "descriptionSite")!,
      minWidth: 200,
      flex: 1,
    },
    {
      field: "calendarStatusName",
      headerName:t(TRASNLATION_PATH + "status")!,
    },
    {
      field: "tasySchedulingType",
      headerName: t(TRASNLATION_PATH + "tasySchedulingType")!,
      width: 30,
      renderCell: ({ row: { tasySchedulingType } }) => (
        <Type type={tasySchedulingType.code!} />
      ),
    },
  ];

  const onRowClick: GridEventListener<"rowClick"> = useCallback(
    ({ row }) => {
      navigate(
        `/schedule/mySchedules/${row.tasySchedulingType.code}/${row.identifier}`
      );
    },
    [navigate]
  );

  return (
    <Box height={"100%"}>
      <Table
        columns={columns}
        rows={data.map((c) => ({ ...c, id: c.identifier }))}
        rowHeight={150}
        rowAction={onRowClick}
      />

      {data.length === 0 && (
        <Grid container mt={5}>
          <Grid item xs={12} sm={12}>
            <NoData />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default memo(MySchedulesTable);
