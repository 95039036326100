import { Stack, Typography } from "@mui/material";
import { ReactNode, memo } from "react";
import { To } from "react-router-dom";

import BackButton from "./BackButton";

export interface IPagesHeaderProps {
  title?: string | null;
  subtitle?: string;
  redirectTo?: To;
  rowActions?: ReactNode;
  headerBottom?: React.ReactNode;
  hideBackButton?: boolean;
  hideHeader?: boolean;
}

const PagesHeader: React.FC<IPagesHeaderProps> = ({
  title,
  redirectTo,
  rowActions,
  subtitle,
  headerBottom,
  hideBackButton = false,
  hideHeader = false,
}) => {
  if (hideHeader) {
    return null;
  }

  return (
    <Stack
      sx={(t) => ({
        position: "sticky",
        top: 0,
        backgroundColor: t.palette.background.default,
        zIndex: 99,
        gap: 1,
        pb: 2,
        alignItems: "start",
      })}
    >
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
      >
        {!hideBackButton && <BackButton iconButton redirectTo={redirectTo} />}
        {rowActions}
      </Stack>

      {title && <Typography variant="h5">{title}</Typography>}
      {subtitle && <Typography variant="subtitle2">{subtitle}</Typography>}

      {headerBottom}
    </Stack>
  );
};

export default memo(PagesHeader);
