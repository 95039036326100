import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import Actions from "./Actions";
import Schedules from "./MySchedules/Schedules";
import PagesContainer from "../../components/UI/PagesContainer";

const ScheduleHome = () => {
  const { t } = useTranslation();
  return (
    <PagesContainer title={t("page.schedules.home.schedules")}>
      <Stack gap={3} mr={1}>
        <Actions />
        <Stack>
          <Typography variant="h6">
            {t("page.schedules.home.nextSchedules")}
          </Typography>
          <Schedules />
        </Stack>
      </Stack>
    </PagesContainer>
  );
};

export default ScheduleHome;
